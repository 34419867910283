import { Router } from "./bootstrap";
import { AppLayout } from "./components/AppLayout";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import ErrorBoundary from "./components/ErrorBoundary";
import GlobalContextContainer from "./components/GlobalContext";
import { ScrollToTopOnRoute } from "./components/ScrollToTopOnRoute";
import { snackIt } from "./components/SnackbarManager";
import config from "./config";
import { AddYourTeam } from "./scenes/AddYourTeam";
import { Checkout } from "./scenes/Checkout";
import { Dashboard } from "./scenes/Dashboard";
import { Login } from "./scenes/Login";
import { Onboarding } from "./scenes/Onboarding";
import ThemeProvider from "./themes/ThemeProvider";
import { datadogRum } from "@datadog/browser-rum";

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

datadogRum.init({
  applicationId: config.datadogApplicationId,
  clientToken: config.datadogClientToken,
  site: 'datadoghq.com',
  service: 'provider-portal-' + config.rollbarEnvironment,
  env:  config.rollbarEnvironment,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: () => {
      snackIt.default({
        severity: "error",
        message: "Something went wrong",
      });
    },
  }),
});

export default function AppRouter() {
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ScrollToTopOnRoute />
            <GlobalContextContainer>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<AuthenticatedRoute />}>
                  <Route path="/add-your-team" element={<AddYourTeam />} />
                  <Route element={<AppLayout />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/onboarding" element={<Onboarding />}>
                      <Route path=":sectionId" element={<Onboarding />} />
                    </Route>
                    <Route path="/checkout/:memberId" element={<Checkout />} />
                  </Route>
                </Route>
                <Route
                  path="*"
                  element={<Navigate to="/dashboard" replace />}
                />
              </Routes>
            </GlobalContextContainer>
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
